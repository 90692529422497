@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* width */
/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #e1306c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1306b90;
}

/* Firefox */
/* width */
html {
  scrollbar-width: thin;
  scrollbar-color: #e1306c transparent;
}

/* Handle */
html {
  scrollbar-face-color: #e1306c;
  scrollbar-shadow-color: transparent;
}

/* Handle on hover */
html {
  scrollbar-face-color: #e1306b90;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #252425;
  color: #7c7c7c;
  overflow: auto; /* Allow body scrolling */
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

section {
  height: 100vh;
}

#main {
  overflow-x: hidden;
  position: relative;
}

.navbar {
  box-sizing: border-box;
  display: flex;
  padding: 20px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 1;
  background-color: #252425f8;
}

.navbar > .socials {
  display: flex;
  width: 100px;
  justify-content: space-between;
}

.navbar > .socials > a > i {
  cursor: pointer;
  color: #7c7c7c;
}

.navbar > .socials > a > .fa-instagram:hover {
  transition: 0.2s;
  color: #e1306c;
}

.navbar > .socials > a > .fa-linkedin-in:hover {
  transition: 0.2s;
  color: #0077b5;
}

.navbar > .socials > a > .fa-twitter:hover {
  transition: 0.2s;
  color: #1da1f2;
}

.navbar > .socials > a > .fa-github:hover {
  transition: 0.2s;
  color: black;
}

.navbar > .touch > a:hover {
  transition: 0.2s;
  color: #0077b5;
}

.navbar > .touch > a {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: #7c7c7c;
  text-decoration: none;
  font-family: "Roboto";
}

.navbar > .touch > a > i {
  font-size: 14px;
}

.navbar > .touch > a > p {
  margin: 0;
  margin-left: 10px;
  padding: 0;
}

.intro {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.intro > img {
  width: 100px;
}

.intro > .profession {
  padding-left: 12px;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  letter-spacing: 12px;
  font-size: 13px;
  color: #575756;
}

.intro > .typer > span {
  font-size: 40px;
  color: white;
  font-weight: bold;
}

.intro > .links {
  display: flex;
  justify-content: space-around;
  width: 270px;
  margin-top: 10px;
}

.intro > .links > a {
  color: #e1306c;
  text-decoration: none;
  font-size: 10px;
}

.intro > .links > a:hover {
  color: white;
  transition: 0.2s;
}

.intro > .circle {
  position: absolute;
  z-index: -50;
  border: 1px solid rgba(240, 248, 255, 0.084);
  height: 650px;
  width: 650px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: expand-shrink, glow;
  animation-duration: 1s, 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  overflow: hidden;
}

.delay-1_8 {
  animation-delay: 1.8s;
}

.delay-2_5 {
  animation-delay: 2.5s;
}

.scrolldown {
  --color: #575756;
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
  cursor: pointer;
  margin-left: -12px;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #2a547066;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }

  40% {
    opacity: 1;
    height: 10px;
  }

  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }

  100% {
    height: 3px;
    opacity: 0;
  }
}

.scrolldown {
  margin-top: 50px;
  position: absolute;
  bottom: 20px;
}

.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
  animation: pulse54012 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
  animation: pulse54012 500ms ease infinite alternate 250ms;
}

@keyframes pulse54012 {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes expand-shrink {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.5;
  }
  100% {
    transform: scale(0.65);
  }
}

@keyframes glow {
  from {
    border: 1px solid rgba(240, 248, 255, 0.084);
  }
  to {
    border: 1px solid rgba(240, 248, 255, 0.15);
  }
}

.about {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 300px;
  font-family: "Poppins", sans-serif;
}

.about > h3 {
  padding-left: 12px;
  margin-top: 30px;
  letter-spacing: 12px;
  font-size: 13px;
  color: #575756;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.about > .text {
  display: flex;
}

.about > .text > .head > h4 {
  width: fit-content;
  font-size: 50px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  transform-origin: center;
  white-space: nowrap;
  text-align: center;
}

.about > .text > p {
  margin-top: 20px;
  font-size: 13px;
  color: rgba(240, 248, 255, 0.821);
  line-height: 25px;
}

.about > .text > p > span {
  color: #e1306c;
}

.about > .text > .head {
  animation: floating 3s ease-in-out infinite;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@keyframes floating {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animate-section {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    background-color: red;
  }
}

.hidden {
  width: 100vw;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
}

.experience {
  padding: 50px 100px;
}

.experience > h3 {
  padding-left: 12px;
  margin-top: 30px;
  letter-spacing: 12px;
  font-size: 13px;
  color: #575756;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.experience > .carousel {
  font-family: "Roboto";
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 30px;
  padding: 30px;
}

.experience > .carousel > .carousel-box {
  min-width: 500px; /* Added min-width */
  height: 400px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #2f2e2f3b;
  transition: 0.2s;
  margin: 5px 30px;
  filter: grayscale(1);
}

.experience > .carousel > .carousel-box:hover {
  background-color: #3d3d3d35;
  border-radius: 25px;
  transform: scale(1.04);
  filter: grayscale(0);
  scroll-behavior: smooth;
}

.img {
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 1;
}

.experience > .carousel > .carousel-box > p {
  margin: 0;
  padding: 0;
  align-self: flex-start;
}

.experience > .carousel > .carousel-box > .role {
  text-transform: uppercase;
  margin: 10px 0;
  margin-top: 30px;
  font-size: 13px;
}

.experience > .carousel > .carousel-box > .company {
  color: aliceblue;
  font-size: 11px;
}

.experience > .carousel > .carousel-box > .icons {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}

.experience > .carousel > .carousel-box > .icons > div > i {
  font-size: 20px;
  padding: 15px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.experience > .carousel > .carousel-box > .icons > div > i > img {
  width: 20px;
  margin: 0;
  padding: 0;
}

.experience > .carousel > .carousel-box > .duration {
  font-size: 12px;
}

.experience > .carousel > .carousel-box > .desc > li {
  font-size: 14px;
  margin: 10px 0;
}

.skills {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
}

.skills > h3 {
  padding-left: 12px;
  margin-top: 30px;
  letter-spacing: 12px;
  font-size: 13px;
  color: #575756;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.skills > p {
  text-align: center;
}

.skills > .grid-container {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create 4 equal-width columns */
  grid-template-rows: repeat(4, 1fr); /* Create 4 equal-height rows */
  grid-gap: 10px; /* Set the gap between grid cells */
  justify-items: center; /* Align items horizontally to the center */
  align-items: center;
  width: 500px;
  height: 500px;
}

.skills > .grid-container > .grid-item > .img {
  position: relative;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skills > .grid-container > .grid-item > .img > img {
  width: 50px;
  transition: 0.3s;
}

.skills > .grid-container > .grid-item > .img > .text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills > .grid-container > .grid-item > .img > .text-blur {
  filter: blur(20px);
}

.skills > .grid-container > .grid-item > .img > .show-prof {
  position: absolute;
  visibility: visible;
  background-color: #e1306c;
  height: 60px;
  color: aliceblue;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s, visibility 0.2s; /* Added transition for visibility */
}

@media (max-width: 640px) {
  .intro > .typer > span {
    font-size: 30px;
    color: white;
    font-weight: bold;
  }

  .scrolldown {
    bottom: 40px;
  }

  .skills {
    padding: 20px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .skills > .grid-container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create 4 equal-width columns */
    grid-template-rows: repeat(2, 1fr); /* Create 4 equal-height rows */
    grid-gap: 0px; /* Set the gap between grid cells */
    justify-items: center; /* Align items horizontally to the center */
    align-items: center;
    width: 300px;
    height: 500px;
  }
}

.projects {
  margin: 0;
  margin-top: 100px;
  height: 100vh;
  display: flex;
  width: 100vw !important; /*TODO: Remove this*/
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

.projects > h3 {
  padding-left: 12px;
  margin-top: 30px;
  letter-spacing: 12px;
  font-size: 13px;
  color: #575756;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.projects > .ribbon {
  background-color: #e1306b26;
  position: absolute;
  transform: rotate(165deg);
  z-index: -1;
  height: 35vh;
  width: 130vw;
  overflow: hidden;
}

.projects > .project-container {
  display: flex;
  width: 95vw;
  overflow-x: scroll;
  flex-wrap: nowrap;
  overflow-y: hidden;
  scroll-behavior: smooth;
  height: fit-content;
}

.projects > .project-container > .project {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 95vw;
  max-width: 95vw;
  margin-right: 0px !important;
  color: rgba(240, 248, 255, 0.582);
  height: 70vh;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: 0.3s;
}

.projects > .project-container > .project:hover {
  scale: 1.03;
  backdrop-filter: grayscale(1) blur(25px);
  border-radius: 30px;
}

.projects > .project-container > .project > .img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.projects > .project-container > .project > .img > img {
  width: 250px;
  margin: 0;
  padding: 0;
}

.projects > .project-container > .project > h4 {
  margin: 0;
  margin-top: 50px;
  font-family: "Poppins";
  letter-spacing: 1px;
}

.projects > .project-container > .project > p {
  margin-top: 20px;
  font-size: 13px;
  width: 900px;
  color: rgba(240, 248, 255, 0.821);
  line-height: 25px;
  font-family: "Poppins";
}

.projects > .project-container > .project > p > span {
  color: #e1306c;
}

.projects > .project-container > .project > a {
  margin: 0;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  font-family: "Poppins";
  color: #e1306c;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

.contact > h3 {
  padding-left: 12px;
  margin-top: 30px;
  letter-spacing: 12px;
  font-size: 13px;
  color: #575756;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.contact > h1 {
  text-decoration: underline;
  font-size: 20px;
  font-family: "Poppins";
}

.contact > h1 > span {
  color: #e1306c;
  text-decoration: none !important;
}

.contact > a {
  font-family: "Poppins";
  color: aliceblue;
  margin: 25px;
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
}

.contact > a:hover {
  color: #e1306c;
}

.contact > a > i {
  color: #e1306c;
  transition: 0.2s;
}

.contact > a:hover > i {
  color: aliceblue;
}

.contact > a > i {
  margin-right: 10px;
}

.contact > form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 400px;
}

.contact > form input,
.contact > form textarea {
  background-color: #3a393a;
  border: none;
  font-family: "Poppins";
  padding: 12px;
  width: 100%;
  margin: 3px;
  outline: none;
  color: aliceblue;
  font-size: 15px;
}

.contact > form > .group {
  width: 430px;
}

.contact > form > .group > input {
  width: 100%;
}

.contact > form > .group {
  display: flex;
}

.contact > form > input[type="submit"] {
  width: 420px;
  background-color: #e1306bcc;
  color: aliceblue;
  transition: 0.2s;
  cursor: pointer;
  border: 1px solid #e1306c;
}

.contact > form > input[type="submit"]:hover {
  background-color: transparent;
  color: #e1306bcc;
}

#cr {
  font-size: 10px;
}

@media (max-width: 1300px) {
  .about {
    padding: 0 200px;
  }
}

@media (max-width: 1100px) {
  .about {
    padding: 0 100px;
  }

  .projects > .project-container > .project > p {
    width: 800px;
  }
}

@media (max-width: 850px) {
  .about {
    padding: 0px 10px;
    overflow-x: hidden !important;
    height: fit-content;
  }

  .about > h3 {
    padding-top: 50px;
  }

  .about > .text > .head > h4 {
    font-size: 20px;
    writing-mode: horizontal-tb;
    transform: rotate(360deg);
  }

  .about > .text > .head {
    animation: none;
  }

  .about > .text {
    padding: 0 20px;
    flex-direction: column;
  }

  .experience > .carousel > .carousel-box {
    min-width: 340px;
    padding-bottom: 160px;
  }

  .experience {
    padding: 0;
    margin-bottom: 30px;
  }

  .experience > .carousel {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    padding-bottom: 30px;
  }

  .projects > .project-container > .project > p {
    width: 500px;
  }

  .contact > form {
    width: 300px;
  }

  .contact > form > input[type="submit"] {
    width: 323px;
  }

  .contact > form > .group {
    width: 330px;
  }

  .skills {
    width: 45vw;
  }

  .projects {
    max-width: 100vw;
  }

  .projects > .ribbon {
    height: 30vh;
    width: 150vw;
    overflow-x: hidden;
  }
}

@media (max-width: 600px) {
  .projects > .project-container > .project > p {
    width: 90%;
    padding: 0 10px;
  }

  .projects > .project-container {
    justify-content: space-between;
    gap: 100px;
  }

  .projects > .project-container > .project {
    height: 90vh;
  }

  .experience > h3 {
    font-size: 10px;
    letter-spacing: 10px;
  }

  .experience > .carousel > .carousel-box {
    min-width: fit-content;
    height: 700px;
    margin-bottom: 0;
    padding-bottom: 15px; /* Added min-width */
  }
}
